<script setup>
import { computed, ref } from "vue";
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import { useRoute } from "vue-router";
import http from "axios";

const tipSet = ref(false);
let apiURL = process.env.VUE_APP_API_URL;
const route = useRoute();
const twitchName = ref("Anonymous");
const tipAmount = ref(5);
const message = ref("");
const alert = ref(false);
const alertError = ref(false);
const alertMessage = ref("");
const hideform = ref(false);
const customAmount = ref();
const tipRows = ref([]);

const schema = computed(() => {
  var ccRules = {
    twitchName: Yup.string().required("Name is required").trim(),
  };

  var tipRule =
    tipAmount.value == 0
      ? {
          customAmount: Yup.number()
            .min(5, "Tip amount must greater than or equal to 5")
            .required("Tip amount is required "),
        }
      : [];

  var rules = {
    ...ccRules,
    ...tipRule,
  };

  return Yup.object().shape(rules);
});

if (route.params.sessionID) {
  savePayment();
}

const stripe = Stripe(process.env.VUE_APP_STRIPE_PK);

getTips();

// Create a Checkout Session
async function setTip() {
  let formData = new FormData();
  var amount = tipAmount.value > 0 ? tipAmount.value : customAmount.value;
  formData.append("tipAmount", amount);
  formData.append("twitchName", twitchName.value);
  formData.append("message", message.value);
  const fetchClientSecret = async () => {
    const response = await fetch(apiURL + "setSession", {
      method: "POST",
      body: formData,
    });
    const { clientSecret } = await response.json();
    return clientSecret;
  };

  const checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret,
  });

  // Mount Checkout
  checkout.mount("#checkout");
}

function setTipAmount() {
  tipSet.value = true;
  setTip();
}

async function savePayment() {
  let form = new FormData();
  form.append("sessionID", route.params.sessionID);
  hideform.value = true;
  try {
    const resp = await http.post(process.env.VUE_APP_API_URL + "saveTip", form);
    alert.value = true;
    alertMessage.value = "Thank you for the $" + resp.data.amount + " tip!";
    if (process.env.NODE_ENV == "production") {
      window._paq.push([
        "trackEvent",
        "Tip",
        "Tip submitted " + resp.data.amount,
      ]);
    }
  } catch (err) {
    alert.value = true;
    alertError.value = true;
    alertMessage.value = err.response.data.message;
  }
}

function resetForm() {
  window.location.replace("/tip");
}

async function getTips() {
  try {
    const resp = await http.get(
      process.env.VUE_APP_API_URL + "get-latest-tips"
    );
    tipRows.value = resp.data.tipRows;
  } catch (err) {
    console.log(err);
  }
}

function formatNumber(Amount) {
  parseFloat(Amount);
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Amount);
}
</script>

<template>
  <div class="container my-4">
    <div class="row mb-3">
      <div class="col-8">
        <h1 class="h4">
          Tip <span v-if="!tipSet && !hideform">amount</span
          ><span v-else>DJ Saturn</span>
        </h1>
      </div>
      <div class="col-4 text-end">
        <a
          href="#"
          class="btn btn-primary"
          @click.prevent="resetForm"
          v-if="tipSet"
          >Start over</a
        >
      </div>
    </div>
    <div
      class="alert"
      :class="alertError ? 'alert-danger' : 'alert-success'"
      role="alert"
      v-if="alert"
      v-html="alertMessage"
    ></div>
    <div v-if="!hideform">
      <div class="row" v-show="!tipSet">
        <div class="col-md-8 mb-3">
          <Form
            @submit="setTipAmount"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="row mb-3">
              <div class="col-md-12">
                <input
                  type="radio"
                  class="btn-check"
                  name="tipAmount"
                  id="five"
                  autocomplete="off"
                  v-model="tipAmount"
                  value="5"
                />
                <label class="btn" for="five">$5.00</label>
                <input
                  type="radio"
                  class="btn-check"
                  name="tipAmount"
                  id="ten"
                  autocomplete="off"
                  v-model="tipAmount"
                  value="10"
                />
                <label class="btn" for="ten">$10.00</label>
                <input
                  type="radio"
                  class="btn-check"
                  name="tipAmount"
                  id="fifteen"
                  autocomplete="off"
                  v-model="tipAmount"
                  value="15"
                />
                <label class="btn" for="fifteen">$15.00</label>
                <input
                  type="radio"
                  class="btn-check"
                  name="tipAmount"
                  id="twenty"
                  autocomplete="off"
                  v-model="tipAmount"
                  value="20"
                />
                <label class="btn" for="twenty">$20.00</label>
                <input
                  type="radio"
                  class="btn-check"
                  name="tipAmount"
                  id="other"
                  autocomplete="off"
                  v-model="tipAmount"
                  value="0"
                />
                <label class="btn" for="other">Other</label>
              </div>
            </div>
            <div
              v-if="tipAmount == 0"
              class="form-floating mb-3"
              :class="{ 'is-invalid': errors.customAmount }"
            >
              <Field
                type="text"
                class="form-control"
                name="customAmount"
                id="custom-amount"
                v-model="customAmount"
                placeholder="Tip amount"
                aria-label="Tip amount"
                :class="{ 'is-invalid': errors.customAmount }"
              />
              <label for="custom-amount">Custom amount</label>
              <div class="invalid-feedback">{{ errors.customAmount }}</div>
            </div>
            <div
              class="form-floating mb-3"
              :class="{ 'is-invalid': errors.twitchName }"
            >
              <Field
                type="text"
                class="form-control"
                name="twitchName"
                id="twitch-name"
                v-model="twitchName"
                placeholder="Name for tip"
                aria-label="Name for tip"
                :class="{ 'is-invalid': errors.twitchName }"
              />
              <label for="twitch-name">Your name</label>
              <div class="invalid-feedback">{{ errors.twitchName }}</div>
            </div>
            <div class="form-floating">
              <textarea
                class="form-control mb-3"
                placeholder="Message"
                id="tipMessage"
                style="height: 100px"
                v-model="message"
                maxlength="255"
              ></textarea>
              <label for="tipMessage">Leave a message with your tip</label>
            </div>
            <button type="submit" class="btn btn-primary btn-lg">
              Set Tip
            </button>
          </Form>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">Latest Tips</div>
            <div class="card-body">
              <table class="table table-striped mb-0">
                <thead>
                  <th scope="col">Name</th>
                  <th scope="col" class="text-end">Amount</th>
                </thead>
                <tbody>
                  <tr v-for="(tipRow, index) in tipRows" :key="'tip-' + index">
                    <td scope="row">{{ tipRow.t_name }}</td>
                    <td class="text-end">${{ formatNumber(tipRow.t_amt) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="checkout" v-show="tipSet">
        <!-- Checkout will insert the payment form here -->
      </div>
    </div>
  </div>
</template>
